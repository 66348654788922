import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="div_container1-banner">
        <div className="div_inside1-banner">
          <span className="font_banner1" >Siri </span><br/>
          <span className="font_banner2" >Roongaroonwongs </span><br/><br/><br/><br/>
          <hr/><br/><br/><br/>
          <span className="font_banner3" >Math T & 2D/3D Artist </span> <br/>

          <img style={{width:"1px",height:"20px"}} src="empty.png" /><br/>
          <a target="_blank" href="https://twitter.com/siriake1980">
            <img width="60px" src="twitter_logo.png" /> 
          </a> 
          <img style={{width:"30px",height:"1px"}} src="empty.png" />
          <a target="_blank" href="https://www.instagram.com/siriroongs/">
            <img width="60px" src="ig_logo.png" /> 
          </a> 
          <img style={{width:"30px",height:"1px"}} src="empty.png" />
          <a target="_blank" href="https://www.facebook.com/akemathhouse">
            <img width="60px" src="fb_logo.png" /> 
          </a> 
          <img style={{width:"30px",height:"1px"}} src="empty.png" /> 
        </div>
      </div>

      <div className="div_container-gallery">
        My Art Portfolio
      </div>

      <div className="div_3p">
        <div className="div_3t-1">
          <div className="div1_1-1">
            <img style={{width:"200px"}} src="Foundation_Logo_White_400px.png" /><br/>
            <img style={{width:"10px",height:"30px"}} src="empty.png" /><br/>
            <img className="img1" width="100%" height="100%" src='Witch01_sample.jpg'/><br/>

            <span className="font1_1-1" >The Witch Sorority </span> <br/>
            <img style={{width:"10px",height:"10px"}} src="empty.png" /><br/>
            <span className="font1_1-1  " style={{fontSize:"1.6vmax",lineHeight:" 0px"}} > 2D Digital Painting </span><br/>
            <img style={{width:"30px",height:"1vmax"}} src="empty.png" /> <br/>

            <a className="myButton-1" target="_blank" href="https://foundation.app/@SiriTheMathArtist/witch/1">
              VIEW ON FOUNDATION   
            </a>
          </div>
        </div>

        <div className="div_3t-2">
          <div className="div1_1-1" >
            <img style={{width:"200px"}} src="Foundation_Logo_White_400px.png" /><br/>
            <img style={{width:"10px",height:"30px"}} src="empty.png" /><br/>
            <video id="vdo1" className="img1" poster="Seastar_Poster1.jpg" controls>
              <source src="Seastar1.mp4" type="video/mp4" />
            </video><br/>

            <span className="font1_1-2" >Seastar Of ETH </span> <br/>
            <img style={{width:"10px",height:"10px"}} src="empty.png" /><br/>
            <span className="font1_1-2  " style={{fontSize:"1.6vmax",lineHeight:" 0px"}} > 3D Animation </span><br/>
            <img style={{width:"30px",height:"1vmax"}} src="empty.png" /> <br/>

            <a className="myButton-2" target="_blank" href="https://foundation.app/@SiriTheMathArtist/~/55532">
              VIEW ON FOUNDATION   
            </a>
          </div>
        </div>

        <div className="div_3t-1" style={{backgroundImage:"none", backgroundColor:"rgb(253,58,108)"}}>
          <div className="div1_1-1" >
            <img style={{width:"300px"}} src="OpenSea_FullLogo_White.png" /><br/>
            <img style={{width:"10px",height:"30px"}} src="empty.png" /><br/>
            <img className="img1" width="100%" height="100%" src='Daruma.jpg  '/><br/>

            <span className="font1_1-1" >Daruma </span> <br/>
            <img style={{width:"10px",height:"10px"}} src="empty.png" /><br/>
            <span className="font1_1-1  " style={{fontSize:"1.6vmax",lineHeight:" 0px"}} > 2D Digital Painting </span><br/>
            <img style={{width:"30px",height:"1vmax"}} src="empty.png" /> <br/>

            <a className="myButton-1" target="_blank" href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/48367351977621948099736715910025526459932334876788105096801443811673516277761/">
              VIEW ON OPENSEA  
            </a>
          </div>
        </div>
      </div>

      <div className="div_container1-3" >
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <div className="div1_1-3">
        <img style={{width:"200px"}} src="Foundation_Logo_White_400px.png" /><br/>
        <img style={{width:"10px",height:"30px"}} src="empty.png" /><br/>
          <video id="vdo2" className="video_land1" poster="Durian_poster.jpg" controls>
            <source src="Durianindisquise.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="div1_2-3"><br/><br/>
          <span className="font1_1-3">Durian in Disguise</span> <br/><br/><br/>
          <img style={{width:"10px",height:"10px"}} src="empty.png" /><br/>
            <span className="font1_1-3  " style={{fontSize:"1.6vmax",lineHeight:" 0px"}} > 3D Animation </span><br/>
            <img style={{width:"30px",height:"1vmax"}} src="empty.png" /> <br/>
          <a className="myButton-1" target="_blank" href="https://foundation.app/@SiriTheMathArtist/~/60182">
             VIEW ON FOUNDATION   
          </a>
        </div>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      </div>

      <div className="div_3p">
        <div className="div_3t-3">
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          <div className="div1_1-1">
            <img style={{width:"300px"}} src="OpenSea_FullLogo_White.png" /><br/>
            <img style={{width:"10px",height:"30px"}} src="empty.png" /><br/>
            <video id="vdo3" className="img2" poster="Farewell_bg1.jpg" controls>
              <source src="Farewell.mp4" type="video/mp4" />
            </video><br/>
            
            <span className="font1_1-2" >Farewell, Dad</span> <br/>
            <img style={{width:"10px",height:"10px"}} src="empty.png" /><br/>
            <span className="font1_1-2  " style={{fontSize:"1.4vmax",lineHeight:" 0px"}} > 3D Animation </span><br/>
            <img style={{width:"30px",height:"1vmax"}} src="empty.png" /> <br/>
            <a className="myButton-2"  target="_blank" href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/48367351977621948099736715910025526459932334876788105096801443707219911639041">
              VIEW ON OPENSEA    
            </a>
          </div>
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>

        <div className="div_3t-4">
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          <div className="div1_1-1">
            <img style={{width:"300px"}} src="OpenSea_FullLogo_White.png" /><br/>
            <img style={{width:"10px",height:"30px"}} src="empty.png" /><br/>
            <video id="vdo4" className="img2" poster="Spark_poster1.jpg" controls>
              <source src="Spark.mp4" type="video/mp4" />
            </video><br/>
            <span className="font1_1-2" >Spark </span> <br/>
            <img style={{width:"10px",height:"10px"}} src="empty.png" /><br/>
            <span className="font1_1-2  " style={{fontSize:"1.4vmax",lineHeight:" 0px"}} > 3D Animation </span><br/>
            <img style={{width:"30px",height:"1vmax"}} src="empty.png" /> <br/>
            <a className="myButton-1" style={{backgroundColor:"black"}} target="_blank" href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/48367351977621948099736715910025526459932334876788105096801443685229679083521">
              VIEW ON OPENSEA    
            </a>
          </div>
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>

        <div className="div_3t-5">
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          <div className="div1_1-1">
            <img style={{width:"300px"}} src="OpenSea_FullLogo_White.png" /><br/>
            <img style={{width:"10px",height:"30px"}} src="empty.png" /><br/>
            <video id="vdo5" className="img_sq1" poster="STU_Card_poster1.jpg" controls>
              <source src="stu_card.mp4" type="video/mp4" />
            </video><br/>
            <span className="font1_1-2" >STU Key Card </span> <br/>
            <img style={{width:"10px",height:"10px"}} src="empty.png" /><br/>
            <span className="font1_1-2  " style={{fontSize:"1.4vmax",lineHeight:" 0px"}} > 3D Animation </span><br/>
            <img style={{width:"30px",height:"1vmax"}} src="empty.png" /> <br/>
            <a className="myButton-2" target="_blank" href="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/48367351977621948099736715910025526459932334876788105096801443670936027922532">
              VIEW ON OPENSEA   
            </a>
          </div>
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>
      </div>

      <div className="div_container1-7" >
        <div className="div1_1-3">
          <img style={{width:"300px"}} src="OpenSea_FullLogo_Black.png" /><br/>
          <img style={{width:"10px",height:"30px"}} src="empty.png" /><br/>
          <video id="vdo6" className="video_land1" poster="CocoNFT_poster1.jpg" controls>
            <source src="cocoNFT.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="div1_2-3"><br/><br/>
          <span className="font1_1-7">cocoNFT</span> <br/><br/><br/>
          <img style={{width:"10px",height:"10px"}} src="empty.png" /><br/>
            <span className="font1_1-7  " style={{fontSize:"1.6vmax",lineHeight:" 0px"}} > 3D Animation </span><br/>
            <img style={{width:"30px",height:"1vmax"}} src="empty.png" /> <br/>
          <a className="myButton-3" target="_blank" href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/48367351977621948099736715910025526459932334876788105096801443796280353488897">
             VIEW ON OPENSEA   
          </a>
        </div>
        <img style={{width:"10px",height:"20px"}} src="empty.png" /><br/>
      </div>

      <div className="div_container1-8" >
        <div className="flex2div_1">
          <img style={{width:"300px"}} src="OpenSea_FullLogo_White.png" /><br/>
          <img style={{width:"10px",height:"30px"}} src="empty.png" /><br/>
          <video id="vdo7" className="video_sq1" poster="PythonX_poster1.jpg" controls>
            <source src="PythonX_all1.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="flex2div_2"><br/><br/>
          <span className="font1_1-8">&nbsp;&nbsp;Python-X <br/>&nbsp;&nbsp;collection<br/>
            &nbsp;&nbsp;
            <a className="myButton-2" target="_blank" href="https://opensea.io/collection/python-x-v2">
                VIEW ON OPENSEA   
            </a>
          </span> 
        </div>
        <img style={{width:"10px",height:"20px"}} src="empty.png" /><br/>
      </div>

      <div className="div_ending">
        "I have started my artist life since June,2021.<br/>
        ...And I will never give up anyway." <br/> <br/> 
        <a target="_blank" href="https://twitter.com/siriake1980">
          <img width="100px" src="twitter_logo.png" /> 
        </a> 
        <img style={{width:"30px",height:"1px"}} src="empty.png" />
        <a target="_blank" href="https://www.instagram.com/siriroongs/">
          <img width="100px" src="ig_logo.png" /> 
        </a> 
        <img style={{width:"30px",height:"1px"}} src="empty.png" />
        <a target="_blank" href="https://www.facebook.com/akemathhouse">
          <img width="100px" src="fb_logo.png" /> 
        </a> 
      </div>
    </div>
  );
}

export default App;


